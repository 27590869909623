import { IError } from '../error'
import { IUser, IRegisterUser, IApiGroup } from './models'

export const REGISTER_REQUESTED = 'REGISTER_REQUESTED'
export interface RegisterRequestAction extends IRegisterUser {
  type: typeof REGISTER_REQUESTED
}

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export interface RegisterSuccessAction {
  type: typeof REGISTER_SUCCESS
}

export const REGISTER_FAILURE = 'REGISTER_FAILURE'
export interface RegisterFailureAction {
  type: typeof REGISTER_FAILURE
  error: IError
}

export const LOGIN_REQUESTED = 'LOGIN_REQUESTED'
export interface LoginRequestAction {
  type: typeof LOGIN_REQUESTED
  email: string
  password: string
  remember: boolean
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS
}

export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE
  error: IError
}

export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED'
export interface LogoutRequestAction {
  type: typeof LOGOUT_REQUESTED
}

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export interface LogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS
}

export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export interface LogoutFailureAction {
  type: typeof LOGOUT_FAILURE
  error: IError
}

export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED'
export interface ForgotPasswordRequestAction {
  type: typeof FORGOT_PASSWORD_REQUESTED
  email: string
}

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export interface ForgotPasswordSuccessAction {
  type: typeof FORGOT_PASSWORD_SUCCESS
}

export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export interface ForgotPasswordFailureAction {
  type: typeof FORGOT_PASSWORD_FAILURE
  error: IError
}

export const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED'
export interface ChangePasswordRequestAction {
  type: typeof CHANGE_PASSWORD_REQUESTED
  code: string
  password: string
}

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export interface ChangePasswordSuccessAction {
  type: typeof CHANGE_PASSWORD_SUCCESS
}

export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export interface ChangePasswordFailureAction {
  type: typeof CHANGE_PASSWORD_FAILURE
  error: IError
}

export const RESET_AUTH_STATUS = 'RESET_AUTH_STATUS'
export interface ResetAuthStatusAction {
  type: typeof RESET_AUTH_STATUS
}

export type AuthActionTypes =
  | RegisterRequestAction
  | RegisterSuccessAction
  | RegisterFailureAction
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction
  | LogoutRequestAction
  | LogoutSuccessAction
  | LogoutFailureAction
  | ForgotPasswordRequestAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailureAction
  | ForgotPasswordRequestAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailureAction
  | ChangePasswordRequestAction
  | ChangePasswordSuccessAction
  | ChangePasswordFailureAction
  | ResetAuthStatusAction
