import { put, call, takeLatest } from 'redux-saga/effects'
import {
  GET_NOTIFICATIONS_REQUEST,
  INotificationGroup,
} from '../types/notifications'
import {
  getNotificationsSuccess,
  getNotificationsFailure,
} from '../actions/notifications'
import { prismicFetchNotifications } from '../services/prismic'

const getNotifications = function* (): Generator<any, void, any> {
  try {
    const response: Promise<INotificationGroup> = yield call(
      prismicFetchNotifications,
    )
    yield put(getNotificationsSuccess(response))
  } catch (e) {
    yield put(getNotificationsFailure({ status: null, msg: e }))
  }
}

const watchNotifications = function* (): Generator {
  yield takeLatest(GET_NOTIFICATIONS_REQUEST, getNotifications)
}

export default watchNotifications
