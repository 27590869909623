import React from 'react'
import { Link } from 'react-router-dom'
import { history } from '../../reducers'
import styles from './styles'

type Props = {
  to: string
  name: string
  icon: string
  toggleDrawer: (shouldClose?: any) => void // TODO: type
}

const NavigatorTab = ({ to, name, icon, toggleDrawer }: Props) => {
  const onClick = () => {
    history.push(to)
    toggleDrawer()
  }
  return (
    <li style={styles.li}>
      <Link to={to} onClick={onClick} style={styles.link}>
        <img src={icon} alt={name} style={{ marginRight: 15 }} />
        <p style={styles.text}>{name}</p>
      </Link>
    </li>
  )
}

export default NavigatorTab
