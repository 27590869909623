import { colors } from '../../configs/colors'
import { Styles } from '../../types/styles'

export default {
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  li: {
    listStyleType: 'none',
    padding: '7px 0',
    borderBottom: `1px ${colors.lightgrey1} solid`,
    width: '90%',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '13px',
  },
} as Styles
