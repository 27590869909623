import Prismic from '@prismicio/client'
import { INotificationGroup, IPrismicDocument } from '../types/notifications'
import extractNotificationData from '../utils/extractNotificationData'
import isExpired from '../utils/isExpired'

export const prismicFetchNotifications =
  async (): Promise<INotificationGroup> => {
    let all = []
    const active = []
    try {
      const apiEndpoint = process.env.REACT_APP_PRISMIC_URL
      const client = Prismic.client(apiEndpoint)
      const { apiGroupName } = JSON.parse(localStorage.getItem('user'))
      const response = await client.query(
        Prismic.Predicates.at('document.type', apiGroupName.toLowerCase()),
      )
      if (response && response.results) {
        all = response.results?.map((item) => {
          const noti = extractNotificationData(item)
          if (!isExpired(noti.expireDate)) active.push(noti)
          return noti
        })
      }
      return {
        active,
        all,
      }
    } catch (err) {
      return {
        active,
        all,
      }
    }
  }

export const prismicFetchbyId = async (
  id: string,
): Promise<IPrismicDocument | null> => {
  const apiEndpoint = process.env.REACT_APP_PRISMIC_URL
  const client = Prismic.client(apiEndpoint)
  const response = await client.query(Prismic.Predicates.at('document.id', id))
  if (response) return response.results[0]
  return null
}
