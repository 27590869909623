import React from 'react'
import CSS from 'csstype'
import styles from './styles'

type Props = {
  children: string | JSX.Element
  onClick: () => void
  style?: CSS.Properties
  type?: string
  disabled?: boolean
}

const Button: React.FC<Props & any> = (props) => {
  const {
    children,
    style,
    onClick,
    type = 'button',
    disabled = false,
    ...args
  } = props

  let combinedStyles = styles.container
  if (style)
    combinedStyles = {
      ...combinedStyles,
      ...style,
    }

  return (
    <button
      style={combinedStyles}
      onClick={onClick}
      type={type}
      disabled={disabled}
      {...args}
    >
      {children}
    </button>
  )
}

export default Button
