import { getCSRFTokenApi } from '../sagas/user'

const API = process.env.REACT_APP_BACKEND_URL_PROD

class Api {
  private headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }

  get = async (uri: string): Promise<Response> => {
    const response = await fetch(`${API}${uri}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: this.headers,
    })
    return response
  }

  post = async (uri: string, params = {}): Promise<any> => {
    const token = await getCSRFTokenApi()
    this.headers['CSRF-Token'] = token
    const response = await fetch(`${API}${uri}`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(params),
      credentials: 'include',
      mode: 'cors',
    })
    return response
  }

  put = async (uri: string, params = {}): Promise<Response> => {
    const token = await getCSRFTokenApi()
    this.headers['CSRF-Token'] = token
    const response = await fetch(`${API}${uri}`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(params),
      credentials: 'include',
      mode: 'cors',
    })
    return response
  }

  delete = async (uri: string): Promise<Response> => {
    const token = await getCSRFTokenApi()
    this.headers['CSRF-Token'] = token
    const response = await fetch(`${API}${uri}`, {
      method: 'DELETE',
      headers: this.headers,
      credentials: 'include',
      mode: 'cors',
    })
    return response
  }
}

export default Api
