import { resetNotificationsStatus } from '../actions/notifications'
import { resetTerminalsStatus } from '../actions/terminals'
import { resetAuthStatus } from '../actions/user'
import store from '../configs/store'
import { routes } from '../navigator/routes'
import { history } from '../reducers'

export default (): void => {
  history.push(routes.signin)
  store.dispatch(resetAuthStatus())
  store.dispatch(resetTerminalsStatus())
  store.dispatch(resetNotificationsStatus())
}
