import { IError } from '../error'
import { INotification, IPrismicDocument, INotificationGroup } from './models'

export type { INotification, IPrismicDocument, INotificationGroup }

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST'
export interface GetNotificationsRequestAction {
  type: typeof GET_NOTIFICATIONS_REQUEST
}

export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export interface GetNotificationsSuccessAction {
  type: typeof GET_NOTIFICATIONS_SUCCESS
  notifications: INotificationGroup
}

export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE'
export interface GetNotificationsFailureAction {
  type: typeof GET_NOTIFICATIONS_FAILURE
  error: IError
}

export const ON_NOTIFICATION_READ = 'ON_NOTIFICATION_READ'
export interface OnNotificationReadAction {
  type: typeof ON_NOTIFICATION_READ
  id: string
}

export const RESET_NOTIFICATIONS_STATUS = 'RESET_NOTIFICATIONS_STATUS'
export interface ResetNotificationsStatusAction {
  type: typeof RESET_NOTIFICATIONS_STATUS
}

export type NotificationsActionTypes =
  | GetNotificationsRequestAction
  | GetNotificationsSuccessAction
  | GetNotificationsFailureAction
  | OnNotificationReadAction
  | ResetNotificationsStatusAction
