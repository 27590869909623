import { IError } from '../types/error'
import {
  GET_TERMINALS_FAILURE,
  GET_TERMINALS_REQUEST,
  GET_TERMINALS_SUCCESS,
  GET_TERMINALS_EVENTS_FAILURE,
  GET_TERMINALS_EVENTS_REQUEST,
  GET_TERMINALS_EVENTS_SUCCESS,
  ITerminals,
  RESET_TERMINALS_STATUS,
  TerminalsActionTypes,
  OPEN_TERMINAL,
  IEvents,
  ITerminalEventData,
  GET_TERMINALS_STATUS_REQUEST,
  GET_TERMINALS_STATUS_FAILURE,
  GET_TERMINALS_STATUS_SUCCESS,
  ITerminalStatus,
} from '../types/terminals'

export interface TerminalsState {
  error: IError | null
  terminals: {
    data: ITerminals | null
    requesting: boolean
  }
  terminalsStatus: {
    data: ITerminalStatus[]
    requesting: boolean
  }
  currentTerminal: ITerminalEventData | null
  events: {
    data: IEvents | null
    requesting: boolean
  }
  lastTerminalsFetched: number
}

const initialState: TerminalsState = {
  error: null,
  terminals: {
    data: null,
    requesting: false,
  },
  terminalsStatus: {
    data: [],
    requesting: false,
  },
  currentTerminal: null,
  events: {
    data: null,
    requesting: false,
  },
  lastTerminalsFetched: 0,
}

const Terminals = (
  state: TerminalsState = initialState,
  action: TerminalsActionTypes,
): TerminalsState => {
  switch (action.type) {
    case GET_TERMINALS_REQUEST:
      return {
        ...state,
        terminals: {
          ...state.terminals,
          requesting: true,
        },
        error: null,
      }
    case GET_TERMINALS_STATUS_REQUEST:
      return {
        ...state,
        terminalsStatus: {
          ...state.terminalsStatus,
          requesting: true,
        },
        error: null,
      }
    case GET_TERMINALS_EVENTS_REQUEST:
      return {
        ...state,
        events: {
          ...state.events,
          requesting: true,
        },
        error: null,
      }
    case GET_TERMINALS_SUCCESS:
      return {
        ...state,
        error: null,
        terminals: {
          ...state.terminals,
          data: action.terminals,
          requesting: false,
        },
        lastTerminalsFetched: Date.now(),
      }
    case GET_TERMINALS_STATUS_SUCCESS:
      return {
        ...state,
        error: null,
        terminalsStatus: {
          ...state.terminalsStatus,
          data: action.terminalsStatus,
          requesting: false,
        },
      }
    case GET_TERMINALS_EVENTS_SUCCESS:
      return {
        ...state,
        error: null,
        events: {
          ...state.events,
          data: action.events,
          requesting: false,
        },
      }
    case GET_TERMINALS_FAILURE:
      return {
        ...state,
        error: action.error,
        terminals: {
          ...state.terminals,
          requesting: false,
        },
      }
    case GET_TERMINALS_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
        terminalsStatus: {
          ...state.terminalsStatus,
          requesting: false,
        },
      }
    case GET_TERMINALS_EVENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        events: {
          ...state.events,
          requesting: false,
        },
      }
    case OPEN_TERMINAL:
      return {
        ...state,
        currentTerminal: action.terminal,
      }

    case RESET_TERMINALS_STATUS:
      return initialState
    default:
      return state
  }
}

export default Terminals
