import { IError } from '../types/error'
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  RESET_NOTIFICATIONS_STATUS,
  ON_NOTIFICATION_READ,
  NotificationsActionTypes,
  INotification,
} from '../types/notifications'

export interface NotificationsState {
  requesting: boolean
  error: IError | null
  notifications: INotification[]
  active: INotification[]
}

const initialState: NotificationsState = {
  requesting: false,
  error: null,
  notifications: [],
  active: [],
}

const Notifications = (
  state: NotificationsState = initialState,
  action: NotificationsActionTypes,
): NotificationsState => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        requesting: true,
        error: null,
      }
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        requesting: false,
        error: null,
        notifications: action.notifications.all,
        active: action.notifications.active,
      }
    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.error,
      }
    case ON_NOTIFICATION_READ:
      return {
        ...state,
        requesting: false,
        active: state.active.filter((item) => item.id !== action.id),
      }
    case RESET_NOTIFICATIONS_STATUS:
      return initialState
    default:
      return state
  }
}

export default Notifications
