import { IError } from '../types/error'
import {
  AuthActionTypes,
  REGISTER_FAILURE,
  REGISTER_REQUESTED,
  REGISTER_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUESTED,
  LOGOUT_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCESS,
  RESET_AUTH_STATUS,
} from '../types/user'
import { IApiGroup, IRegisterUser } from '../types/user/models'

export const register = (user: IRegisterUser): AuthActionTypes => ({
  type: REGISTER_REQUESTED,
  ...user,
})

export const registerSuccess = (): AuthActionTypes => ({
  type: REGISTER_SUCCESS,
})

export const registerFailure = (error: IError): AuthActionTypes => ({
  type: REGISTER_FAILURE,
  error,
})

export const login = ({
  email,
  password,
  remember,
}: {
  email: string
  password: string
  remember: boolean
}): AuthActionTypes => ({
  type: LOGIN_REQUESTED,
  email,
  password,
  remember,
})

export const loginSuccess = (): AuthActionTypes => ({
  type: LOGIN_SUCCESS,
})

export const loginFailure = (error: IError): AuthActionTypes => ({
  type: LOGIN_FAILURE,
  error,
})

export const logout = (): AuthActionTypes => ({
  type: LOGOUT_REQUESTED,
})

export const logoutSuccess = (): AuthActionTypes => ({
  type: LOGOUT_SUCCESS,
})

export const logoutFailure = (error: IError): AuthActionTypes => ({
  type: LOGOUT_FAILURE,
  error,
})

export const forgotPassword = (email: string): AuthActionTypes => ({
  type: FORGOT_PASSWORD_REQUESTED,
  email,
})

export const forgotPasswordSuccess = (): AuthActionTypes => ({
  type: FORGOT_PASSWORD_SUCCESS,
})

export const forgotPasswordFailure = (error: IError): AuthActionTypes => ({
  type: FORGOT_PASSWORD_FAILURE,
  error,
})
export const changePassword = (
  code: string,
  password: string,
): AuthActionTypes => ({
  type: CHANGE_PASSWORD_REQUESTED,
  code,
  password,
})

export const changePasswordSuccess = (): AuthActionTypes => ({
  type: CHANGE_PASSWORD_SUCCESS,
})

export const changePasswordFailure = (error: IError): AuthActionTypes => ({
  type: CHANGE_PASSWORD_FAILURE,
  error,
})

export const resetAuthStatus = (): AuthActionTypes => ({
  type: RESET_AUTH_STATUS,
})
