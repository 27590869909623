export const colors = {
  transparent: 'transparent',
  red: '#EB532F',
  white: '#FFFFFF',
  black: '#000000',
  black2: '#212121',
  lightyellow: '#FFF0BC',
  darkgrey: '#101010',
  lightblue: 'lightblue',
  lightgrey1: '#00000014',
  lightgrey2: '#FAFAFA',
  lightgrey3: '#F5F5F5',
  lightgrey4: '#EAEAEA',
  darkblue: 'darkblue',
  span: '#D5D5D5',
}
