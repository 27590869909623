import { colors } from '../../configs/colors'
import { Styles } from '../../types/styles'

export default {
  container: {
    width: '100vw',
  },
  close: {
    position: 'absolute',
    right: '4px',
    top: '4px',
    padding: '10px',
  },
  drawer: {
    width: '300px', // TODO: responsive
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  nav: {
    height: '100vh',
  },
  sideNav: {
    position: 'absolute',
    left: '5.7vw',
    top: '10px',
    padding: '20px 10px',
  },
  logoWrapper: {
    height: '85px',
    alignSelf: 'center',
    marginBottom: '27px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.lightgrey1,
  },
  back: {
    width: '15px',
    height: '15px',
  },
  backText: {
    marginLeft: '20px',
  },
} as Styles
