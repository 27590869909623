import { put, call, takeLatest } from 'redux-saga/effects'
import {
  FORGOT_PASSWORD_REQUESTED,
  LOGIN_REQUESTED,
  LOGOUT_REQUESTED,
  CHANGE_PASSWORD_REQUESTED,
  REGISTER_REQUESTED,
  AuthActionTypes,
  LOGIN_FAILURE,
} from '../types/user'
import ApiWrapper from '../services/apiWrapper'
import { ApiResponse } from '../types/apiResponse'
import {
  changePasswordFailure,
  changePasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  getApiGroupsSuccess,
  loginFailure,
  loginSuccess,
  logoutFailure,
  logoutSuccess,
  registerFailure,
  registerSuccess,
} from '../actions/user'
import resetOnLogout from '../utils/resetOnLogout'
import { history } from '../reducers'
import { routes } from '../navigator/routes'
import { IRegisterUser } from '../types/user/models'

const registerUrl = '/auth/signup'
const loginUrl = '/auth/signin'
const logoutUrl = '/auth/signout'
const forgotPasswordUrl = '/auth/password/forgot?email=%email'
const changePasswordUrl = '/auth/password/change'
const getCSRFTokenUrl = '/auth/csrf'
const getApiGroupsUrl = '/api-groups'

const apiCall = ApiWrapper

const getApiGroupsApi = async (): Promise<ApiResponse | null> => {
  try {
    const response: Response = await apiCall.get(getApiGroupsUrl)
    const json = await response.json()
    return json?.data
  } catch (err) {
    return null
  }
}

export const getCSRFTokenApi = async (): Promise<ApiResponse | null> => {
  try {
    const response: Response = await apiCall.get(getCSRFTokenUrl)
    const json = await response.json()
    return json?.data?.CSRFToken
  } catch (err) {
    return null
  }
}

const registerApi = async (user: IRegisterUser): Promise<ApiResponse> => {
  const response: Response = await apiCall.post(registerUrl, user)
  const json = await response.json()
  return json
}

const loginApi = async ({
  email,
  password,
  remember,
}: {
  email: string
  password: string
  remember: boolean
}): Promise<ApiResponse> => {
  const response: Response = await apiCall.post(loginUrl, {
    email,
    password,
    remember,
  })
  const json = await response.json()
  return json
}

const forgotPasswordApi = async (email: string): Promise<ApiResponse> => {
  const response: Response = await apiCall.get(
    forgotPasswordUrl.replace('%email', email),
  )
  const json = await response.json()
  return json
}

const changePasswordApi = async (
  code: string,
  password: string,
): Promise<ApiResponse> => {
  const response: Response = await apiCall.post(changePasswordUrl, {
    code,
    password,
  })
  const json = await response.json()
  return json
}

const logoutApi = async (): Promise<ApiResponse> => {
  const response: Response = await apiCall.get(logoutUrl)
  const json = await response.json()
  return json
}

const register = function* ({
  type,
  ...user
}: AuthActionTypes): Generator<any, void, any> {
  try {
    const response: ApiResponse = yield call(registerApi, user)
    const { status, data, error } = response
    if (status !== 200) {
      yield put(registerFailure({ status, msg: error }))
    } else {
      history.push(routes.home)
      // TODO: data is new user
      yield put(registerSuccess())
    }
  } catch (e) {
    yield put(
      registerFailure({
        status: null,
        msg: e as string,
      }),
    )
  }
}

const login = function* ({
  email,
  password,
  remember,
}: any): Generator<any, void, any> {
  try {
    const response: ApiResponse = yield call(loginApi, {
      email,
      password,
      remember,
    })
    const { status, data, error } = response
    if (status !== 200) {
      yield put(loginFailure({ status, msg: error }))
    } else {
      history.push(routes.home)
      const apiGroups = yield call(getApiGroupsApi)
      localStorage.setItem('apiGroups', JSON.stringify(apiGroups))
      const userApiGroup = apiGroups.find((item) => item.id === data.apiGroupId)
      const user = { ...data, apiGroupName: userApiGroup.name }
      yield put(loginSuccess())
      localStorage.setItem('user', JSON.stringify(user))
    }
  } catch (e) {
    yield put(
      loginFailure({
        status: null,
        msg: e as string,
      }),
    )
  }
}

const forgotPassword = function* ({ email }: any): Generator<any, void, any> {
  try {
    const response: ApiResponse = yield call(forgotPasswordApi, email)
    const { status, data, error } = response
    if (status !== 200) {
      yield put(forgotPasswordFailure({ status, msg: error }))
    } else {
      yield put(forgotPasswordSuccess())
    }
  } catch (e) {
    yield put(
      forgotPasswordFailure({
        status: null,
        msg: e as string,
      }),
    )
  }
}

const changePassword = function* ({
  code,
  password,
}: any): Generator<any, void, any> {
  try {
    const response: ApiResponse = yield call(changePasswordApi, code, password)
    const { status, error } = response
    if (status !== 200) {
      yield put(changePasswordFailure({ status, msg: error }))
    } else {
      yield put(changePasswordSuccess())
      history.push('/')
    }
  } catch (e) {
    yield put(
      changePasswordFailure({
        status: null,
        msg: e as string,
      }),
    )
  }
}

const logout = function* (): Generator {
  try {
    const response: ApiResponse = yield call(logoutApi)
    const { status, error } = response
    if (status !== 200) {
      yield put(logoutFailure({ status, msg: error || '' }))
    } else {
      yield put(logoutSuccess())
    }
  } catch (e) {
    yield put(logoutFailure({ status: null, msg: 'Tuntematon virhe' }))
  } finally {
    yield call(resetOnLogout)
  }
}

const handleLoginFailure = function* (): Generator {
  history.push(routes.signin)
}

const watchUser = function* (): Generator {
  yield takeLatest(REGISTER_REQUESTED, register)
  yield takeLatest(LOGIN_REQUESTED, login)
  yield takeLatest(LOGOUT_REQUESTED, logout)
  yield takeLatest(FORGOT_PASSWORD_REQUESTED, forgotPassword)
  yield takeLatest(CHANGE_PASSWORD_REQUESTED, changePassword)
  yield takeLatest(LOGIN_FAILURE, handleLoginFailure)
}

export default watchUser
