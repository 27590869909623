import { put, call, takeLatest } from 'redux-saga/effects'
import ApiWrapper from '../services/apiWrapper'
import { ApiResponse } from '../types/apiResponse'
import {
  GET_TERMINALS_EVENTS_REQUEST,
  GET_TERMINALS_REQUEST,
  GET_TERMINALS_STATUS_REQUEST,
} from '../types/terminals'
import {
  getTerminalsSuccess,
  getTerminalsFailure,
  getTerminalsEventsSuccess,
  getTerminalsEventsFailure,
  getTerminalsStatusSuccess,
  getTerminalsStatusFailure,
} from '../actions/terminals'

const apiCall = ApiWrapper

const getTerminalsUrl = '/terminals'
const getTerminalsStatusUrl = '/terminals/status'
const getTerminalsEventsUrl = '/terminals/events'

const getTerminalsApi = async (): Promise<ApiResponse> => {
  const response: Response = await apiCall.get(getTerminalsUrl)
  const json = await response.json()
  return json
}

const getTerminalsStatusApi = async (): Promise<ApiResponse> => {
  const response: Response = await apiCall.get(getTerminalsStatusUrl)
  const json = await response.json()
  return json
}

const getTerminalsEventsApi = async (): Promise<ApiResponse> => {
  const response: Response = await apiCall.get(getTerminalsEventsUrl)
  const json = await response.json()
  return json
}

const getTerminals = function* (): Generator<any, void, any> {
  try {
    const response: ApiResponse = yield call(getTerminalsApi)
    const { status, data, error } = response
    if (status !== 200) {
      yield put(getTerminalsFailure({ status, msg: error }))
    } else {
      yield put(getTerminalsSuccess(data))
    }
  } catch (e) {
    yield put(getTerminalsFailure({ status: null, msg: e }))
  }
}

const getTerminalsStatus = function* (): Generator<any, void, any> {
  try {
    const response: ApiResponse = yield call(getTerminalsStatusApi)
    const { status, data, error } = response
    if (status !== 200) {
      yield put(getTerminalsStatusFailure({ status, msg: error }))
    } else {
      yield put(getTerminalsStatusSuccess(data))
    }
  } catch (e) {
    yield put(getTerminalsStatusFailure({ status: null, msg: e }))
  }
}

const getTerminalsEvents = function* (): Generator<any, void, any> {
  try {
    const response: ApiResponse = yield call(getTerminalsEventsApi)
    const { status, data, error } = response
    if (status !== 200) {
      yield put(getTerminalsEventsFailure({ status, msg: error }))
    } else {
      yield put(getTerminalsEventsSuccess(data))
    }
  } catch (e) {
    yield put(getTerminalsEventsFailure({ status: null, msg: e }))
  }
}

const watchTerminals = function* (): Generator {
  yield takeLatest(GET_TERMINALS_REQUEST, getTerminals)
  yield takeLatest(GET_TERMINALS_STATUS_REQUEST, getTerminalsStatus)
  yield takeLatest(GET_TERMINALS_EVENTS_REQUEST, getTerminalsEvents)
}

export default watchTerminals
