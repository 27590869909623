import React, { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { routes } from './routes'

const Signup = lazy(() => import('../screens/Signup'))
const SignIn = lazy(() => import('../screens/SignIn'))
const Home = lazy(() => import('../screens/Home'))
const Info = lazy(() => import('../screens/Info'))
const Events = lazy(() => import('../screens/Events'))
const ForgotPassword = lazy(() => import('../screens/ForgotPassword'))
const ResetPassword = lazy(() => import('../screens/ResetPassword'))

const { register, signin, home, info, events, forgotPassword, resetPassword } =
  routes

const HomeSwitch = (): JSX.Element => (
  <Switch>
    <Route exact path={home}>
      <Home />
    </Route>
    <Route exact path={info}>
      <Info />
    </Route>
    <Route exact path={events}>
      <Events />
    </Route>
  </Switch>
)

const SignInSwitch = (): JSX.Element => (
  <Switch>
    <Route exact path={signin}>
      <SignIn />
    </Route>
    <Route exact path={forgotPassword}>
      <ForgotPassword />
    </Route>
    <Route exact path={register}>
      <Signup />
    </Route>
    <Route exact path={resetPassword}>
      <ResetPassword />
    </Route>
  </Switch>
)

export const renderAuthenticated = (): JSX.Element => <HomeSwitch />
export const renderUnAuthenticated = (): JSX.Element => <SignInSwitch />
