import { colors } from '../../configs/colors'
import { Styles } from '../../types/styles'

export default {
  container: {
    border: 'none',
    backgroundColor: colors.transparent,
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    margin: '0',
    padding: '0',
  },
} as Styles
