import { INotification } from '../types/notifications'

export default (props: any): INotification => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data, first_publication_date, type, id, last_publication_date } =
    props
  const { description, moreinfo, name, expireDate } = data
  const notification: INotification = {
    id,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    publishedAt: first_publication_date,
    name: name[0].text,
    description: description[0].text,
    moreInfo: moreinfo[0].text,
    lastPublicationDate: last_publication_date,
    expireDate: expireDate[0].text,
  }
  return notification
}
