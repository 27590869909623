import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'

import reducer from '../reducers'
import { rootSaga } from '../sagas'

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]
console.log(`process.env.NODE_ENV`, process.env.NODE_ENV)
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger)
}

const persistConfig = {
  key: 'root',
  storage,
  timeout: 0, // solution for bug: rehydrate for "root" called after timeout
  whitelist: ['client', 'login'],
}
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(...middleware)),
)
sagaMiddleware.run(rootSaga)
export const persistor = persistStore(store)

export default store
