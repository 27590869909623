import { IError } from '../types/error'
import {
  OPEN_TERMINAL,
  GET_TERMINALS_FAILURE,
  GET_TERMINALS_REQUEST,
  GET_TERMINALS_SUCCESS,
  GET_TERMINALS_STATUS_FAILURE,
  GET_TERMINALS_STATUS_REQUEST,
  GET_TERMINALS_STATUS_SUCCESS,
  GET_TERMINALS_EVENTS_FAILURE,
  GET_TERMINALS_EVENTS_REQUEST,
  GET_TERMINALS_EVENTS_SUCCESS,
  ITerminals,
  RESET_TERMINALS_STATUS,
  TerminalsActionTypes,
  IEvents,
  ITerminalStatus,
  ITerminalEventData,
} from '../types/terminals'

export const getTerminals = (): TerminalsActionTypes => ({
  type: GET_TERMINALS_REQUEST,
})

export const getTerminalsSuccess = (
  terminals: ITerminals,
): TerminalsActionTypes => ({
  type: GET_TERMINALS_SUCCESS,
  terminals,
})

export const getTerminalsFailure = (error: IError): TerminalsActionTypes => ({
  type: GET_TERMINALS_FAILURE,
  error,
})

export const getTerminalsStatus = (): TerminalsActionTypes => ({
  type: GET_TERMINALS_STATUS_REQUEST,
})

export const getTerminalsStatusSuccess = (
  terminalsStatus: ITerminalStatus[],
): TerminalsActionTypes => ({
  type: GET_TERMINALS_STATUS_SUCCESS,
  terminalsStatus,
})

export const getTerminalsStatusFailure = (
  error: IError,
): TerminalsActionTypes => ({
  type: GET_TERMINALS_STATUS_FAILURE,
  error,
})

export const getTerminalsEvents = (): TerminalsActionTypes => ({
  type: GET_TERMINALS_EVENTS_REQUEST,
})

export const getTerminalsEventsSuccess = (
  events: IEvents,
): TerminalsActionTypes => ({
  type: GET_TERMINALS_EVENTS_SUCCESS,
  events,
})

export const getTerminalsEventsFailure = (
  error: IError,
): TerminalsActionTypes => ({
  type: GET_TERMINALS_EVENTS_FAILURE,
  error,
})

export const resetTerminalsStatus = (): TerminalsActionTypes => ({
  type: RESET_TERMINALS_STATUS,
})

export const openTerminal = (
  terminal: ITerminalEventData,
): TerminalsActionTypes => ({
  type: OPEN_TERMINAL,
  terminal,
})
