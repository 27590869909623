import { IError } from '../error'
import {
  ITerminals,
  ITerminalStatus,
  IEvents,
  IEventData,
  ITerminalEventData,
} from './models'

export type {
  ITerminals,
  ITerminalStatus,
  IEvents,
  IEventData,
  ITerminalEventData,
}

export const GET_TERMINALS_REQUEST = 'GET_TERMINALS_REQUEST'
export interface GetTerminalsRequestAction {
  type: typeof GET_TERMINALS_REQUEST
}

export const GET_TERMINALS_SUCCESS = 'GET_TERMINALS_SUCCESS'
export interface GetTerminalsSuccessAction {
  type: typeof GET_TERMINALS_SUCCESS
  terminals: ITerminals
}

export const GET_TERMINALS_FAILURE = 'GET_TERMINALS_FAILURE'
export interface GetTerminalsFailureAction {
  type: typeof GET_TERMINALS_FAILURE
  error: IError
}

export const GET_TERMINALS_STATUS_REQUEST = 'GET_TERMINALS_STATUS_REQUEST'
export interface GetTerminalsStatusRequestAction {
  type: typeof GET_TERMINALS_STATUS_REQUEST
}

export const GET_TERMINALS_STATUS_SUCCESS = 'GET_TERMINALS_STATUS_SUCCESS'
export interface GetTerminalsStatusSuccessAction {
  type: typeof GET_TERMINALS_STATUS_SUCCESS
  terminalsStatus: ITerminalStatus[]
}

export const GET_TERMINALS_STATUS_FAILURE = 'GET_TERMINALS_STATUS_FAILURE'
export interface GetTerminalsStatusFailureAction {
  type: typeof GET_TERMINALS_STATUS_FAILURE
  error: IError
}

export const GET_TERMINALS_EVENTS_REQUEST = 'GET_TERMINALS_EVENTS_REQUEST'
export interface GetTerminalsEventsRequestAction {
  type: typeof GET_TERMINALS_EVENTS_REQUEST
}

export const GET_TERMINALS_EVENTS_SUCCESS = 'GET_TERMINALS_EVENTS_SUCCESS'
export interface GetTerminalsEventsSuccessAction {
  type: typeof GET_TERMINALS_EVENTS_SUCCESS
  events: IEvents
}

export const GET_TERMINALS_EVENTS_FAILURE = 'GET_TERMINALS_EVENTS_FAILURE'
export interface GetTerminalsEventsFailureAction {
  type: typeof GET_TERMINALS_EVENTS_FAILURE
  error: IError
}

export const OPEN_TERMINAL = 'OPEN_TERMINAL'
export interface OpenTerminalAction {
  type: typeof OPEN_TERMINAL
  terminal: ITerminalEventData
}

export const RESET_TERMINALS_STATUS = 'RESET_TERMINALS_STATUS'
export interface ResetTerminalsStatusAction {
  type: typeof RESET_TERMINALS_STATUS
}

export type TerminalsActionTypes =
  | GetTerminalsRequestAction
  | GetTerminalsSuccessAction
  | GetTerminalsFailureAction
  | GetTerminalsStatusRequestAction
  | GetTerminalsStatusSuccessAction
  | GetTerminalsStatusFailureAction
  | GetTerminalsEventsRequestAction
  | GetTerminalsEventsSuccessAction
  | GetTerminalsEventsFailureAction
  | OpenTerminalAction
  | ResetTerminalsStatusAction
