import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import User from './user'
import Terminals from './terminals'
import Notifications from './notifiations'

export const history = createBrowserHistory()

const rootReducer = combineReducers({
  router: connectRouter(history),
  user: User,
  terminals: Terminals,
  notifications: Notifications,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
