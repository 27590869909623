import React, { Suspense, useEffect } from 'react'
import { Router, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { renderAuthenticated, renderUnAuthenticated } from './navigator'
import Fallback from './shared/Fallback'
import DrawerNav from './navigator/DrawerNav'
import { history, RootState } from './reducers'

function App() {
  const location = useLocation()

  /* useEffect(() => {
    if (!loggedIn) {
      if (
        location.pathname !== '/login' &&
        !location.pathname.includes('password')
      )
        history.push('/login')
    } 
  }, [loggedIn, location]) */

  return (
    <Suspense fallback={<Fallback />}>
      <Router history={history}>
        <DrawerNav />
        {renderAuthenticated()}
        {renderUnAuthenticated()}
      </Router>
    </Suspense>
  )
}

export default App
