import React, { useState } from 'react'
import { Drawer } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { routes } from '../routes'
import assets from '../../assets'
import Button from '../../shared/Button'
import styles from './styles'
import NavigatorTab from '../../shared/NavigatorTab'
import { logout } from '../../actions/user'

const DrawerNav: React.FC = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : {}

  const toggleDrawer = (shouldClose?: boolean): void =>
    setOpen(typeof shouldClose === 'boolean' ? shouldClose : !open)

  const { home, info, signin, signup } = routes

  const showNavigatorIcon = (): JSX.Element | null => {
    const canGoback = location.pathname === '/events'
    if (canGoback) return null
    return (
      <Button
        style={styles.sideNav}
        children={<img src={assets.menu} alt="Menu" />}
        onClick={toggleDrawer}
      />
    )
  }

  if (location.pathname !== routes.home && location.pathname !== routes.info)
    return null

  return (
    <div style={styles.container}>
      {showNavigatorIcon()}
      <Drawer
        variant="temporary"
        open={open}
        onClose={() => toggleDrawer(true)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div style={styles.drawer}>
          <Button
            children={<img src={assets.close} alt="Close" />}
            onClick={toggleDrawer}
            style={styles.close}
          />
          <div style={styles.logoWrapper}>
            <img src={assets.logoSVG} alt="Logo" id="logo" className="logo" />
          </div>
          <nav style={styles.nav}>
            <ul>
              <NavigatorTab
                to={home}
                name="Pysäköintiautomaatit"
                icon={assets.terminals}
                toggleDrawer={toggleDrawer}
              />
              <NavigatorTab
                to={info}
                name="Tiedotteet"
                icon={assets.info}
                toggleDrawer={toggleDrawer}
              />
              {user.admin && (
                <NavigatorTab
                  to={signup}
                  name="Rekisteröidy"
                  icon={assets.logout} // TODO: signup icon
                  toggleDrawer={toggleDrawer}
                />
              )}
              <NavigatorTab
                to={signin}
                name="Kirjaudu ulos"
                icon={assets.logout}
                toggleDrawer={() => {
                  dispatch(logout())
                  toggleDrawer()
                }}
              />
            </ul>
          </nav>
        </div>
      </Drawer>
    </div>
  )
}

export default DrawerNav
