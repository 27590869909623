import down from './down.svg'
import up from './up.svg'
import check from './check.svg'
import exclam from './exclam.svg'
import menu from './menu.svg'
import question from './question.svg'
import logout from './logout.svg'
import info from './info.svg'
import terminals from './terminals.svg'
import close from './close.svg'
import back from './back.svg'
import logoSVG from './logo.svg'

export default {
  down,
  check,
  exclam,
  menu,
  question,
  up,
  info,
  terminals,
  close,
  logout,
  back,
  logoSVG,
}
