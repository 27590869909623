import { IError } from '../types/error'
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  NotificationsActionTypes,
  RESET_NOTIFICATIONS_STATUS,
  ON_NOTIFICATION_READ,
  INotificationGroup,
  INotification,
} from '../types/notifications'

export const getNotifications = (): NotificationsActionTypes => ({
  type: GET_NOTIFICATIONS_REQUEST,
})

export const getNotificationsSuccess = (
  notifications: INotificationGroup,
): NotificationsActionTypes => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  notifications,
})

export const getNotificationsFailure = (
  error: IError,
): NotificationsActionTypes => ({
  type: GET_NOTIFICATIONS_FAILURE,
  error,
})

export const onNotificationRead = (id: string): NotificationsActionTypes => ({
  type: ON_NOTIFICATION_READ,
  id,
})

export const resetNotificationsStatus = (): NotificationsActionTypes => ({
  type: RESET_NOTIFICATIONS_STATUS,
})
